import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Skill Type Tab`}</h1>
    <p>{`In this tab, there is the option to activate and configure the general parameters of the different channels that this team will be assigned to. It is normal for a team to work with several channels simultaneously. However, this management varies from client to client, and also from team to team.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "906px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/62fc8293413d785978ea6720db1efd20/56077/skill-type-tab.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABkklEQVQoz6WSW2/TMBiG88/5KxzEEHdMULjhCg0Qh2nZWgkELb1ZaHOys8RxDrbzILsbamFIk/ikV3ktOY/f77Oj49Mlzz5/C3p+tuSFV7xitq/zFS9v0auLnWbxkteLH9w7PiH6vqlo6oZG5PxvHb2fE3nTj4aruqZRLY1SjMZgrMNYe/11WHe7BmNR/RiAD07Od8BhNJSlQEqJkBJr3cHJ0zThnPtL0+RCmLrtw76Hb2MizBjS1VcVnVYMfYcUJevVksViTpIk4aC2bSmFQFR18MpLNUhZ0bQdWMf9N6dEOBNSZbIllZpUKNaXGV/XW7ZZSSUlSimsbz+Mwgbv5VNqrclFQTXmPHp35oGWQkiSUnNZaJKyQyqDUAbjDtu+NsHfrPuuJ5cF2Zjw+EO8S5gVgq3QpLLjp+jQgzmY3bQHmf4A+oTbPEWYlKMAnGyYzV6WkMK56Z+gfWBd12Fkvp5+uiDCjqRZxmazoaqq38nuIl/+wvz/PsiTj/Hu2bRah1vruu7OsBvgMAyhbV+z+Rd+AczZSk73ABG/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/62fc8293413d785978ea6720db1efd20/e93cc/skill-type-tab.webp 300w", "/static/62fc8293413d785978ea6720db1efd20/b0544/skill-type-tab.webp 600w", "/static/62fc8293413d785978ea6720db1efd20/dd896/skill-type-tab.webp 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/62fc8293413d785978ea6720db1efd20/eed55/skill-type-tab.png 300w", "/static/62fc8293413d785978ea6720db1efd20/7491f/skill-type-tab.png 600w", "/static/62fc8293413d785978ea6720db1efd20/56077/skill-type-tab.png 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/62fc8293413d785978ea6720db1efd20/56077/skill-type-tab.png",
              "alt": "skill-type-tab",
              "title": "skill-type-tab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Note: Don't forget to activate the skill type you need.`}</p>
    </blockquote>
    <h2>{`Skill Type: Voice`}</h2>
    <p>{`Define the type of Contact Center connection, activate voicemail detection, configure the number to present to visitor (Caller ID), transfer calls and check the timetable of users for this channel.`}</p>
    <p>{`The following configurations are available:`}</p>
    <h3>{`Connection Type`}</h3>
    <p>{`Defines the operating mode of the voice engine. There are two modes: CoreMedia Cloud Contact Center or connect to an external contact center via phone number.`}</p>
    <h4>{`(A) If CoreMedia Cloud Contact Center was selected`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1190px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/13711c46b1556cd160781eecf4f2f4b3/88169/connection-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABTklEQVQ4y62TW0+DQBCF9///FaPv6pvRXt9Lb1Rs0wbo1hgbYJdlOeas2VqItDHxJF8Im5nDzOwgev0BoihCGIbY7nZI0xRJiziOEa5WmC8WeFuvkaZ7JEl6Ik4S7KXEJJhCzMMVskLj/ePTPS2AqoWxNYqiwPF4hDEGXZJSQtA1z3MUhUJpDKytYa09o3YmWZZBKeVgvKcsSxdDsRsxm82hlUJVVc6gJnXdgEk0an6oCRXHCQRnQjHRq23Yde7xhvJwgFiGoauOFbAS0pV4zZAXI6bTmTugodb6V0OeXZLvQsoDxGsUXW3ZV/AnQ95kV0uX5teYIVteLJduhtwztu33rJ3kV8bDnPO40wwnQeBMlNJuhlqX7t1UFYz5xi+1nzH5iTEOVrnd7iDWmw3+S1meQ9zc3mEwGoP/dBdPzy/oDYbod8CY4WiM+4dHfAG97+wPs9WCewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/13711c46b1556cd160781eecf4f2f4b3/e93cc/connection-1.webp 300w", "/static/13711c46b1556cd160781eecf4f2f4b3/b0544/connection-1.webp 600w", "/static/13711c46b1556cd160781eecf4f2f4b3/bfe78/connection-1.webp 1190w"],
              "sizes": "(max-width: 1190px) 100vw, 1190px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/13711c46b1556cd160781eecf4f2f4b3/eed55/connection-1.png 300w", "/static/13711c46b1556cd160781eecf4f2f4b3/7491f/connection-1.png 600w", "/static/13711c46b1556cd160781eecf4f2f4b3/88169/connection-1.png 1190w"],
              "sizes": "(max-width: 1190px) 100vw, 1190px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/13711c46b1556cd160781eecf4f2f4b3/88169/connection-1.png",
              "alt": "connection-1.png",
              "title": "connection-1.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h5>{`Operators device:`}</h5>
    <p>{`Operators can choose the type of device that will interact with our Cloud Contact Center. Webphone and softphone are supported.`}</p>
    <p>{`We recommend only using the webphone as it reduces the probability of problems and the number of applications used by the operator.`}</p>
    <h5>{`Answering mode`}</h5>
    <p>{`Defines the way a call is delivered to the operator, either manually where he has to accept it through a message on the console or the call is delivered automatically.`}</p>
    <h5>{`Answering strategy:`}</h5>
    <p>{`Defines call routing strategy, who is the operator to receive a call:`}</p>
    <p><strong parentName="p">{`Least Recent`}</strong>{`: calls are delivered to the free operator who received the last call the longest ago, i.e., has been out of calls the longest amount of time;`}</p>
    <p><strong parentName="p">{`Fewest Calls`}</strong>{`: calls are delivered to the free operator who has the fewest calls;
Random: calls are randomly assigned to a free operator;`}</p>
    <p><strong parentName="p">{`Round Robin`}</strong>{`: calls are distributed equally across the operators instead of sending more calls to one operator over the other.`}</p>
    <p>{`All these strategies are calculated per day.`}</p>
    <blockquote>
      <p parentName="blockquote">{`NOTE:
Here’s an example of round robin call routing: Operator X, Y, and Z are currently available to answer incoming calls. The first call that comes in goes to operator X because they were first in line. The next call goes to operator Y, and then Z.`}</p>
    </blockquote>
    <h5>{`Permission for inbound via mobile phone`}</h5>
    <p>{`Operator can receive a call on a specific number (e.g., on his/her cell phone).`}</p>
    <h6>{`Voicemail`}</h6>
    <p><strong parentName="p">{`Activate voicemail detection`}</strong>{`:`}</p>
    <p>{`Possibility to activate and deactivate voicemail detection on calls and define what to do in three cases:`}</p>
    <ul>
      <li parentName="ul">{`Identify as a human;`}</li>
      <li parentName="ul">{`Identify as a machine;`}</li>
      <li parentName="ul">{`Detection was not conclusive.`}</li>
    </ul>
    <p>{`Available Action settings for each voicemail detection result:`}</p>
    <ul>
      <li parentName="ul">{`Human - Proceed | Async DTMF validation`}</li>
      <li parentName="ul">{`Machine - Proceed | Initial DTMF validation | Async DTMF validation | Hang Up`}</li>
      <li parentName="ul">{`Not Sure - Proceed | Initial DTMF validation | Async DTMF validation | Hang Up`}</li>
    </ul>
    <p>{`What does it mean?`}</p>
    <p><strong parentName="p">{`Initial DTMF Validation`}</strong></p>
    <p>{`An extra audio is played asking if "the contact wants to continue with the call", if there is interaction via DTMF it is assumed there is a person on the other end and the call continues. Otherwise the call is disconnected;`}</p>
    <p><strong parentName="p">{`Async DTMF Detection`}</strong></p>
    <p>{`Should be used in very specific cases normally when the call is redirected to an IVR. With this option active, an extra service is "listening" for X second which monitors the leg of the contact waiting for a DTMF. If there is no DTMF during the listening time the call is disconnected;`}</p>
    <blockquote>
      <p parentName="blockquote">{`Only the total managers can edit any action settings for each voicemail detection result.
Managers can change Voicemail detection activation from Yes, No (or Team Settings) for each Team/Skill.
For a Total Manager to set Async DTMF validation as an action for any type of AMD results they must be sure that the call is being forward to an IVR Skill granting that the user will use a DTMF.`}</p>
    </blockquote>
    <h6>{`Call actions`}</h6>
    <p>{`Can be set whether or not the operator can hang up and or reject a call and operator can access to voice actions.`}</p>
    <h6>{`Caller ID`}</h6>
    <p>{`An outbound and/or Click2Call number can be defined that will be used to make the call.`}</p>
    <h6>{`Alerts`}</h6>
    <p>{`Can be set whether or not alerts and/or notifications should be displayed.`}</p>
    <h6>{`Transfers`}</h6>
    <p>{`Allows the operator to transfer a call to other skills or to external numbers. A list of skills or a list of external numbers needs to be provided.`}</p>
    <p>{`Attended transfers allows both operators to talk before transferring the visitor/contact to a new operator. During this period the contact will be on hold and the first operator has the control of transfering the call or cancel it.`}</p>
    <p>{`For blind transfer, operator selects where to transfer and blind transfer type. Once it's answered Operator 1 abandons the call.`}</p>
    <h6>{`Timetable`}</h6>
    <p>{`Per default for this channel, define working hours, hours of automatic calls (ex. recoveries) and days off.
Defining different times for the launch of automatic calls is only available within the set working hours of the team/channel.
Can be set how the beyond business hours will behave.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1ece17c4df48d91fcbe6f50342e3257d/6f2aa/beyond-business-hours.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxklEQVQoz4VS2QrDMAzL///mYE+loyxpa+fQUIp7ZD0EIuDKspzUTdOEcRxXzrNARCEiB6rqgUtdoTFuNVW4GCNyznjCNnCuZkRJEf79gnw/kBCQaRhCQNd16Pu+igkOKKWspAHNmGJfpy4xUEooOdd+RzHXJpl239CaUmNna250nDwMQ53WJjOymZvwrKlS+tMSHOYs3VUykgZcx7QMwebThBSywe7kjPaKV9/329VH8d4vr3aT0NCu2cLZP/aU0AzuroYJf3pOxeJrrn90AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1ece17c4df48d91fcbe6f50342e3257d/e93cc/beyond-business-hours.webp 300w", "/static/1ece17c4df48d91fcbe6f50342e3257d/b0544/beyond-business-hours.webp 600w", "/static/1ece17c4df48d91fcbe6f50342e3257d/68fc1/beyond-business-hours.webp 1200w", "/static/1ece17c4df48d91fcbe6f50342e3257d/7b980/beyond-business-hours.webp 1394w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1ece17c4df48d91fcbe6f50342e3257d/eed55/beyond-business-hours.png 300w", "/static/1ece17c4df48d91fcbe6f50342e3257d/7491f/beyond-business-hours.png 600w", "/static/1ece17c4df48d91fcbe6f50342e3257d/8537d/beyond-business-hours.png 1200w", "/static/1ece17c4df48d91fcbe6f50342e3257d/6f2aa/beyond-business-hours.png 1394w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1ece17c4df48d91fcbe6f50342e3257d/8537d/beyond-business-hours.png",
              "alt": "beyond-business-hours",
              "title": "beyond-business-hours",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h6>{`Queue position & estimate time announcement`}</h6>
    <p>{`The “in-queue position” feature is designed to inform customers of their current place in the waiting line, providing transparency and helping to manage their expectations regarding wait times.`}</p>
    <p><strong parentName="p">{`Allow queue position & Estimative time announcement`}</strong>{` - Select No/Position/estimate wait time/position & time`}</p>
    <p><strong parentName="p">{`Announcement interval`}</strong>{` -
Write the number of seconds that define the interval between announcements. For example, 30. Minimum value allowed is 30.`}</p>
    <p><strong parentName="p">{`Announcement position limit`}</strong>{` -
Select Yes to only play announcements if the call is in the top of the queue.`}</p>
    <p><strong parentName="p">{`Limit calls`}</strong>{` -
Write the size of the top of the queue. For example, 5 calls.`}</p>
    <h6>{`Audios`}</h6>
    <p>{`You can associate uploaded audio files to team skills. To associate an audio file to a team skill, open app Teams, search for the team, search for the skill and associate audio files that the skill can use.`}</p>
    <p>{`This how to guide shows how to associate MUSIC ON HOLD as a working example. You can follow the same procedure for other types of audio files.`}</p>
    <h4>{`(B) If Contact Center Number was selected`}</h4>
    <p>{`Although the mode of operation is very different, there are some features of the Cloud Contact Center (CCC) service that can be used as well. We will list the specific options in the configuration below.`}</p>
    <h5>{`Operator identification mode`}</h5>
    <p>{`Connected to an external contact center, it’s necessary to know how to identify the operator behind a telephone. Here, an operator can enter a code to identify himself.`}</p>
    <h5>{`Reversed Call`}</h5>
    <p>{`There is a possibility to define the order of the calls. In the normal scenario, and in order to maximize the contact center operation, a call is made to the contact and, only after he answers, another call is made to the operator. In the inverted mode, the connection is established the opposite way.`}</p>
    <h5>{`DTMF Generator`}</h5>
    <p>{`Defines how the code to identify an operator will be generated. A sequential or random code can be used. We recommend the use of a random code because there is less probability of inserting wrong codes.`}</p>
    <h2>{`Skill Type: Chat`}</h2>
    <p>{`In this option, you can configure the following:`}</p>
    <ul>
      <li parentName="ul">{`The operator can use the “enter” key to send messages;`}</li>
      <li parentName="ul">{`Join all contact conversations from the same visitor;`}</li>
      <li parentName="ul">{`Show browser alerts (notification and/or sound) when a new chat or message occurs;`}</li>
      <li parentName="ul">{`The operator can transfer contacts;`}</li>
      <li parentName="ul">{`Timetable per default for this channel, define working hours and days off.`}</li>
      <li parentName="ul">{`Automatic end messages.`}</li>
    </ul>
    <p>{`Define persistence, connectivity, and downtime time, as well as the messages to be presented to the visitor and the operator at each moment. Messages can be personalized by language.`}</p>
    <h3>{`Persistence Time`}</h3>
    <p>{`Time defined in 60 second intervals, in which the chat remains active after the visitor stops responding. Once this time is reached, the defined message is sent, and the chat is terminated. The default time is 3600 seconds (1 hour).`}</p>
    <h3>{`Connectivity Timeout`}</h3>
    <p>{`Time defined in 15 second intervals, in which the chat stays active after the visitor loses connection. Once this time is reached, the message is sent, and the chat is ended. The default time is 45 seconds.`}</p>
    <h3>{`Downtime`}</h3>
    <p>{`Time defined in 60 second intervals, in which the chat is persisted after the visitor stops answering. As soon as this time is reached, the defined message is sent. In this case, the chat does not end. It only serves as a warning for the Persistence Time. The default time is 3300 seconds (55 minutes).`}</p>
    <h2>{`Skill Type: Forms`}</h2>
    <p>{`There are no generic settings for this type of channel. Here the only options are to activate and/or deactivate.`}</p>
    <h2>{`Skill Type: Tickets`}</h2>
    <p>{`Configurations available for this channel are:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Distribution Mode`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Defines ticketing routing strategy, who is the operator to receive a ticket:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Occupation rate`}</strong>{`, tickets are delivered to the operator with lower occupancy rate;`}</li>
              <li parentName="ul"><strong parentName="li">{`Random`}</strong>{`, tickets are randomly assigned to an operator;`}</li>
              <li parentName="ul"><strong parentName="li">{`Round Robin`}</strong>{`, tickets are distributed evenly by the operators instead of sending more tickets to one operator over the other;`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Attribution`}
            <ul parentName="li">
              <li parentName="ul">{`Only operators logged less than X days are eligible for the previous distribution mode.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Define alert level`}
            <ul parentName="li">
              <li parentName="ul">{`Show browser notification`}</li>
              <li parentName="ul">{`Define the alert to new ticket or to new message (none, notification, sound or notification and sound)`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      